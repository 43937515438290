// app/javascript/packs/nested-forms/addFields.js
class addFields {
    // This executes when the function is instantiated.
    constructor() {
        this.links = document.querySelectorAll('.add_fields')
        this.containers = document.querySelectorAll('.nested-fields-container')
        this.iterateLinks()
    }

    iterateLinks() {
        // If there are no links on the page, stop the function from executing.
        if (this.links.length === 0) return
        // Loop over each link on the page. A page could have multiple nested forms.
        this.links.forEach(link => {
            link.addEventListener('click', e => {
                this.handleClick(link, e)
            })
        })
    }

    handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Save a unique timestamp to ensure the key of the associated array is unique.
        let time = new Date().getTime()
        // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
        let linkId = link.dataset.id
        // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
        let regexp = linkId ? new RegExp(linkId, 'g') : null
        // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
        let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
        // Add the new markup to the form if there are fields to add.
        if (newFields) {
            this.containers.forEach(container => {
                container.insertAdjacentHTML('beforebegin', newFields)
            });
        }
    }
}

// app/javascript/packs/nested-forms/removeFields.js
class removeFields {
    // This executes when the function is instantiated.
    constructor() {
        this.iterateLinks()
    }

    iterateLinks() {
        // Use event delegation to ensure any fields added after the page loads are captured.
        document.addEventListener('click', e => {
            if (e.target && e.target.classList.contains('remove_fields')) {
                this.handleClick(e.target, e)
            }
        })
    }

    handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Find the parent wrapper for the set of nested fields.
        let fieldParent = link.closest('.nested-fields')
        // If there is a parent wrapper, find the hidden delete field.
        let deleteField = fieldParent
            ? fieldParent.querySelector('input[type="hidden"]')
            : null
        // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
        if (deleteField) {
            deleteField.value = 1
            fieldParent.style.display = 'none'
        }
    }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new addFields())
window.addEventListener('turbolinks:load', () => new removeFields())