var gl = null;
var canvas = null;

var iTimeUniform = null;
var iResolutionUniform = null;
var primaryColorUniform = null;
var secondaryColorUniform = null;

var time = 0;
var running = false;
const delta_fps = 1000 / 30;

const indices = [0, 1, 2, 1, 2, 3];

window.initializeBackgroundCanvas = function (colors) {
    canvas = document.getElementById("background-canvas");
    // canvas.width = window.innerWidth;
    // canvas.height = window.innerHeight;

    gl = canvas.getContext('webgl2');

    if (gl === null) {
        // looks like WeblGL2 is not supported, EOC
        // TODO: Stupid shader could need only WebGL1
        return;
    }

    const vertices = [
        -1.0, -1.0, 0.0,
        -1.0, 1.0, 0.0,
        1.0, -1.0, 0.0,
        1.0, 1.0, 0.0
    ]

    let vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    let indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);

    /*================ Shaders ====================*/
    const vertCode =
        '#version 300 es\n' +
        'in vec3 coordinates;\n' +
        'void main(void) {\n' +
        ' gl_Position = vec4(coordinates, 1.0);\n' +
        '}';

    let vertShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertShader, vertCode);
    gl.compileShader(vertShader);
    let message = gl.getShaderInfoLog(vertShader);
    if (message.length > 0) {
        console.warn(message);
    }

    const fragCode = "" +
        "#version 300 es\n" +
        "precision highp float;\n" +
        "out vec4 fragColor;\n" +
        "uniform vec2 iResolution;\n" +
        "uniform float iTime;\n" +
        "uniform vec4 primaryColor;\n" +
        "uniform vec4 secondaryColor;\n" +
        "#define SEGMENTCOUNT 32\n" +
        "#define PI 3.14159265359\n" +
        "\n" +
        "void main()\n" +
        "{\n" +
        "    vec2 nodePosition = iResolution.xy/2.0;\n" +
        "    \n" +
        "    vec2 fragPos = gl_FragCoord.xy - nodePosition;\n" +
        "    vec2 normalFragPos = normalize(gl_FragCoord.xy - nodePosition);\n" +
        "    \n" +
        "    float angleSegment = (2.0 * PI) / float(SEGMENTCOUNT);\n" +
        "    float angle = atan(normalFragPos.y, normalFragPos.x) + PI + (iTime * 0.00005);\n" +
        "    \n" +
        "    int segmentId = int(angle / angleSegment);\n" +
        "    fragColor = (segmentId % 2 == 0) ? primaryColor : secondaryColor;\n" +
        //        "    fragColor = (segmentId % 2 == 0) ? vec4(0.929, 0.156, 0.576, 1) : vec4(0.725, 0.058, 0.423, 1);\n" +
        "}";

    let fragShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragShader, fragCode);
    gl.compileShader(fragShader);
    message = gl.getShaderInfoLog(fragShader);
    if (message.length > 0) {
        console.warn(message);
    }

    let shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertShader);
    gl.attachShader(shaderProgram, fragShader);
    gl.linkProgram(shaderProgram);
    gl.useProgram(shaderProgram);

    /*======= Associating shaders to buffer objects =======*/

    iTimeUniform = gl.getUniformLocation(shaderProgram, "iTime");
    iResolutionUniform = gl.getUniformLocation(shaderProgram, "iResolution");
    primaryColorUniform = gl.getUniformLocation(shaderProgram, "primaryColor");
    secondaryColorUniform = gl.getUniformLocation(shaderProgram, "secondaryColor");

    // Bind vertex buffer object
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);

    // Bind index buffer object
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

    // Get the attribute location
    let coord = gl.getAttribLocation(shaderProgram, "coordinates");

    // Point an attribute to the currently bound VBO
    gl.vertexAttribPointer(coord, 3, gl.FLOAT, false, 0, 0);

    // Enable the attribute
    gl.enableVertexAttribArray(coord);

    const colorsMap = {
        pink: {
            primary: [0.929, 0.156, 0.576, 1],
            secondary: [0.725, 0.058, 0.423, 1]
        },
        blue: {
            primary: [0.176, 0.776, 0.839, 1],
            secondary: [0.149, 0.705, 0.764, 1]
        },
        zebra: {
            primary: [0, 0, 0, 1],
            secondary: [1, 1, 1, 1]
        }
        /*ukraine: {
            primary: [0, 0.356, 0.733, 1],
            secondary: [1, 0.835, 0, 1]
        }*/
    };

    const colorIndex = Math.floor(Math.random() * colors.length)

    gl.uniform4fv(primaryColorUniform, colorsMap[colors[colorIndex]].primary)
    gl.uniform4fv(secondaryColorUniform, colorsMap[colors[colorIndex]].secondary)


    if (!running) {
        renderLoop();
    }
}

function render() {
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.viewport(0, 0, canvas.width, canvas.height);
    gl.uniform2f(iResolutionUniform, canvas.width, canvas.height);
    gl.uniform1f(iTimeUniform, time);

    gl.drawElements(gl.TRIANGLES, indices.length, gl.UNSIGNED_SHORT, 0);
    time += delta_fps;
}

function renderLoop() {
    running = true;
    render();
    window.setTimeout(renderLoop, delta_fps);
}
